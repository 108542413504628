(function (){
  const logo = document.querySelector('.logo')
  const burger = document.querySelector('.burger')
  const burgerIcon = burger.querySelector('use')
  const navigation = document.querySelector('nav')
  const navigationBoxShadow = navigation.style.boxShadow
  const navigationZIndex = navigation.style.zIndex

  const nav = document.querySelector('.nav')
  const navOverlay = document.querySelector('.nav__overlay')
  const navLinks = document.querySelectorAll('.nav__link')

  let isMenuVisible = false

  function showMenu() {
    if (isMenuVisible) {
      return
    }

    isMenuVisible = true
    document.body.style.overflow = 'hidden'
    navigation.style.zIndex = '100'
    navigation.style.boxShadow = 'none'
    nav.classList.add('nav--visible')
    navOverlay.classList.add('nav__overlay--visible')
    burgerIcon.setAttribute('href', '#close')
  }

  function hideMenu() {
    if (!isMenuVisible) {
      return
    }

    isMenuVisible = false
    document.body.style.overflow = 'auto'
    navigation.style.zIndex = navigationZIndex
    navigation.style.boxShadow = navigationBoxShadow
    nav.classList.remove('nav--visible')
    navOverlay.classList.remove('nav__overlay--visible')
    burgerIcon.setAttribute('href', '#burger')
  }

  function toggleMenuVisibility() {
    isMenuVisible ? hideMenu() : showMenu()
  }

  burger.addEventListener('click', toggleMenuVisibility)
  navOverlay.addEventListener('click', hideMenu)
  navLinks.forEach(link => link.addEventListener('click', hideMenu))
  logo.addEventListener('click', hideMenu)
})()
